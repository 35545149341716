
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default Vue.extend({
  name: "PhonePicker",

  components: {
    VueTelInput
  },

  props: {
    value: {
      required: true
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    rules: {
      required: false,
      type: Array,
      default: () => [] as Array<any>
    },
    errorMessages: {
      required: false,
      default: null
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    phone: {} as object,
    phoneNumber: "" as string,
    error: "" as string,
    focus: false
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.phoneNumber = this.value as string;
          this.$nextTick(() => {
            this.phoneNumber = this.phoneNumber.slice(1);
          });
        }
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.$watch(
        () => {
          return (this.$refs.input as any).messagesToDisplay;
        },
        ([value]) => {
          this.error = value;
        }
      );
    }, 0);
  },

  methods: {
    input(_: string, phoneObject: any) {
      if (typeof phoneObject.valid == "undefined") {
        return;
      }

      this.phone = phoneObject;

      if (phoneObject.valid) {
        this.$emit("input", phoneObject.number);
      } else {
        this.$emit("input", "");
      }
    }
  }
});
