
import Vue from "vue";
import UserHelp from "@/components/form-components/UserHelp.vue";

export default Vue.extend({
  name: "UserHelpDialog",
  components: { UserHelp },
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    helpDialogShowed: false as boolean,
    windowSize: {
      x: 0
    },
    services: [
      {
        id: 2,
        alias: "aaij",
        name: "AAIJ",
        description: "Notice of attestat. of justifying impediment",
        color: "#2D99DE",
        icon_url: "https://cci-api.powerit.dev/img/aaij.png"
      },
      {
        id: 5,
        alias: "exp",
        name: "EXP",
        description: "Expertise of goods",
        color: "#2D99DE",
        icon_url: "https://cci-api.powerit.dev/img/exp.png"
      },
      {
        id: 4,
        alias: "eval",
        name: "EVAL",
        description: "Goods evaluation",
        color: "#2D99DE",
        icon_url: "https://cci-api.powerit.dev/img/eval.png"
      },
      {
        id: 3,
        alias: "cert",
        name: "CERT",
        description: "Certificate of  nonpreferential origin",
        color: "#2D99DE",
        icon_url: "https://cci-api.powerit.dev/img/cert.png"
      },
      {
        id: 1,
        alias: "ata",
        name: "ATA",
        description: "ATA carnet",
        color: "#2D99DE",
        icon_url: "https://cci-api.powerit.dev/img/ata.png"
      }
    ] as Array<any>
  }),

  async mounted() {
    this.onResize();
  },

  watch: {
    value() {
      this.helpDialogShowed = this.value;
    }
  },

  methods: {
    getColor(color: any, defaultColor = ""): string {
      if (color.id === this.serviceId) {
        return color.color;
      }
      return defaultColor;
    },
    closeDialog() {
      this.helpDialogShowed = false;
      this.$emit("input", this.helpDialogShowed);
    },
    onResize() {
      this.windowSize = { x: window.innerWidth };
    }
  }
});
