
import Vue from "vue";
import LangSwitcher from "@/components/settings/LangSwitcher.vue";
import rules from "@/services/helpers/validation-rules";
import DatePicker from "@/components/form-components/DatePicker.vue";
import { mask } from "vue-the-mask";
import PhonePicker from "@/components/form-components/PhonePicker.vue";
import UserHelpDialog from "@/components/dialogs/UserHelpDialog.vue";

export default Vue.extend({
  name: "Login",

  components: { DatePicker, LangSwitcher, PhonePicker, UserHelpDialog },

  directives: {
    mask
  },

  data: () => ({
    rules,
    logo: require("@/assets/images/logos/logo.png") as any,
    model: {
      cci_member: false
    } as any,
    personalDataAgreement: false as boolean,
    errorMessage: "" as any,
    shownPassword: {
      new: false as boolean,
      confirm: false as boolean
    } as any,
    forgot: false as boolean,
    organizationFormItems: [] as Array<SelectComponentInterface>,
    helpDialogShowed: false as boolean
  }),

  computed: {
    selects(): object {
      return {
        types: [
          {
            value: "physical",
            text: this.$t("auth.selects.physical")
          },
          {
            value: "juridical",
            text: this.$t("auth.selects.juridical")
          }
        ]
      };
    },
    computedRules(): object {
      return {
        samePassword: this.samePassword
      };
    },
    isJuridical() {
      return this.model.type === "juridical";
    }
  },

  beforeMount() {
    this.loadSelects();
  },

  methods: {
    samePassword(): any {
      return (
        this.model.password === this.model.password_confirmation ||
        this.$t("auth.validation.same_password")
      );
    },
    async submitForm(): Promise<void> {
      try {
        if (await (this.$refs.form as any).validate()) {
          await this.$API.auth().registerClient(this.model);
          await this.$router.push(`/auth/email-sent/${this.model.email}`);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
        if (e.hasOwnProperty("errors")) {
          this.errorMessage = e.errors;
          setTimeout(() => {
            this.errorMessage = {};
          }, 2000);
        }
      }
    },
    async loadSelects(): Promise<void> {
      try {
        this.organizationFormItems = await this.$API
          .organizationForms()
          .getOrganizationFormsList();
      } catch (e) {
        console.log(e);
      }
    },
    changeModel() {
      if (this.model.type === "juridical") {
        this.model.identity_card = undefined;
        this.model.identity_card_date = undefined;
        this.model.identity_card_issued = undefined;
      }
    },
    togglePasswordType(key: string): void {
      this.shownPassword[key] = !this.shownPassword[key];
    },
    showPassword(key: string): void {
      this.shownPassword[key] = true;
    },
    hidePassword(key: string): void {
      this.shownPassword[key] = false;
    },
    getPasswordType(key: string): string {
      if (this.shownPassword[key]) {
        return "text";
      }

      return "password";
    }
  }
});
